<script lang="ts" setup>
const { isMobile } = useDevice();
const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();

const {
	recentWinners,
	jackpotGames,
	packages,
	lastWinners,
	seoData,
	jackpots,
	newGames,
	refresh: refreshHomeData
} = useHomePage();
const { tournamentData } = useRace();
const { refresh: refreshQuestData } = useGetPageQuestData({
	immediate: !isGuest.value
});
const { questGames } = useQuestTask();
const { isActiveDailyLogin } = useDailyLogin();
const { seasonInitData } = useSeasonsData({ immediate: false });

useHead({
	title: seoData.value?.seoTitle || "FunzCity",
	meta: [
		{
			name: "description",
			content: seoData.value?.seoDescription
		},
		{
			name: "keywords",
			content: seoData.value?.seoKeywords || ""
		}
	]
});
const refFavorites = ref(recentWinners);

const { item } = useRecentGamesWinners({ enableThrottling: true });

const { homePromotions } = usePromotionsData();
const { isSupercharged } = useMysteryBoxes();

const allPackages = computed(() => {
	const promoPackages = [];

	if (packages.value?.promoOffers?.bestDeal) {
		promoPackages.push({
			money: packages.value.promoOffers.bestDeal.money,
			promoOfferPreset: packages.value.promoOffers.bestDeal,
			preset: null
		});
	}

	if (packages.value?.promoOffers?.mostPopular) {
		promoPackages.push({
			money: packages.value.promoOffers.mostPopular.money,
			promoOfferPreset: packages.value.promoOffers.mostPopular,
			preset: null
		});
	}

	return [...(packages.value?.packages || []), ...promoPackages]
		.sort((promoA, promoB) => promoA.money - promoB.money)
		.sort((promo) => (promo.promoOfferPreset && promo.promoOfferPreset.bestDeal ? -1 : 1));
});

const monthLastWinners = computed(() => lastWinners?.value?.win || []);
const multiLastWinners = computed(() => lastWinners?.value?.coefficient || []);

const resentWinners = computed(() => ({
	day: refFavorites.value?.data,
	month: monthLastWinners.value,
	multi: multiLastWinners.value
}));

onMounted(() => {
	watch(
		item,
		(value) => {
			refFavorites.value?.data?.unshift(value);
		},

		{ deep: true }
	);

	watch(isGuest, (value) => {
		if (!value) {
			refreshQuestData();
			return;
		}

		nextTick(() => {
			refreshHomeData();
		});
	});

	refreshHomeData();
});
</script>

<template>
	<NuxtLayout>
		<div class="box-banners" :class="{ 'box-banners--logged-in': !isGuest }">
			<OBanners />

			<OHomeBannersPromotions
				v-if="!isGuest && allPackages && allPackages.length"
				:allPackages="allPackages"
				:allPromotions="homePromotions?.promotions"
			/>
		</div>

		<LazyOOpportunity v-if="isGuest" class="box-opportunity" />

		<div v-if="!isGuest" id="box-widgets" class="box-widgets">
			<LazyOHomeBannerSeason v-if="seasonInitData?.isEnabled" class="left-banner" />
			<LazyOHomeBannerVIP v-else class="left-banner" />

			<LazyOHomeBannerDailyLogin v-if="isActiveDailyLogin" class="center-banner" />
			<LazyOHomeBannerMistery v-else class="center-banner" />

			<OLazyComponent v-if="isMobile && newGames?.length" id="lazy-new-games" class="games-benefits first-widget">
				<LazyOGames :rows="2" :games="newGames || []" />
			</OLazyComponent>

			<OLazyComponent id="lazy-active-feature" :prerender="!isMobile" class="home-benefit-banner">
				<LazyORacesBenefitCard v-if="tournamentData?.isActiveStatus" />
				<LazyOHomeBannerQuest v-else />
			</OLazyComponent>

			<OLazyComponent v-if="isMobile && questGames?.length" id="lazy-quest-games" class="games-benefits second-widget">
				<LazyOGames :rows="2" :games="questGames || []" />
			</OLazyComponent>
		</div>

		<LazyOHomeSectionGames :itemSection="0" />

		<OLazyComponent id="recent-winners" class="box-recent-winners">
			<LazyORecentGames :games="resentWinners || []" :supercharged-box="isSupercharged" />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="1" />

		<LazyOHomeJackpot :game-mode="appInitData?.gameMode" :jackpots="jackpots" :games="jackpotGames" />

		<LazyOHomeSectionGames :itemSection="2" />

		<OLazyComponent v-if="!isGuest" id="box-promotions-games" class="box-promotions">
			<LazyOHomePromotions />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="3" />

		<OLazyComponent v-if="isGuest" id="box-benefits" class="box-benefits">
			<LazyOBenefits />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="4" />

		<div class="delimiter" />
	</NuxtLayout>
</template>

<style lang="scss" scoped>
.swiper-slide {
	width: 207px;

	@include media-breakpoint-down(md) {
		width: 164px;
		padding-right: 10px;
	}
}

.section-title {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 32px;
	gap: 0 16px;
	position: relative;
	padding: 0 16px;

	@include media-breakpoint-down(md) {
		margin-bottom: 16px;
	}
}

.games-list__item {
	width: 193px;
	min-height: 134px;
}

.slider {
	&-mobile {
		display: none;
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	&-desktop {
		display: block;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.box-banners {
	&--logged-in {
		width: 1312px;
		max-width: calc(100% - 32px);
		margin: 16px auto 0;
		display: flex;
		justify-content: space-between;
		gap: 16px;

		@include media-breakpoint-down(lg) {
			gap: 32px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}

		@media (orientation: landscape) and (max-width: 992px) {
			flex-direction: column;
		}

		&:deep {
			.slider__wrapper {
				margin-bottom: 0;
				border-radius: 16px;
				height: 376px;

				.fader__slide {
					border-radius: 16px;
					overflow: hidden;
				}

				&::before {
					content: none;
				}

				@include media-breakpoint-down(md) {
					height: 196px;
				}
			}
		}
	}
}

.box-widgets {
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 24px auto 0;
	display: flex;
	justify-content: space-between;
	gap: 16px;

	@include media-breakpoint-down(lg) {
		flex-wrap: wrap;
	}

	@include media-breakpoint-down(md) {
		margin: 48px auto 0;
		flex-wrap: initial;
		flex-direction: column;
	}

	.left-banner {
		order: 1;
	}

	.center-banner {
		order: 2;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				order: 3;
			}
		}

		@include media-breakpoint-down(sm) {
			order: unset;
		}
	}

	&-games {
		display: none;

		&:deep {
			& > div:nth-child(n + 7) {
				display: none;
			}
		}

		@include media-breakpoint-down(md) {
			display: grid;
			margin: 8px 0 40px;
			max-width: 100%;
		}
	}

	.home-benefit-banner {
		order: 3;

		@include media-breakpoint-down(lg) {
			width: calc(100% - 311px);

			@media (orientation: landscape) {
				order: 4;
			}
		}

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	&:deep(.games-benefits) {
		margin: 8px 0;
		width: 100%;

		&.first-widget {
			order: 4;

			@include media-breakpoint-down(lg) {
				@media (orientation: landscape) {
					order: 2;
				}
			}

			@include media-breakpoint-down(sm) {
				order: 2;
			}
		}

		&.second-widget {
			order: 5;

			@include media-breakpoint-down(sm) {
				order: 4;
			}
		}

		.grid-cards {
			margin-bottom: 32px;
			max-width: 100%;
			width: 100%;
		}
	}
}

.box-favorites {
	margin: 40px 0 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.box-all-games {
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include media-breakpoint-down(md) {
		margin-top: 40px;
	}

	.section-title {
		.icon-star {
			position: absolute;
			font-size: 41px;
			right: -65px;
			top: -13px;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				right: -15px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 16px;
				right: 30px;
			}
		}
		.icon-star-blur {
			position: absolute;
			font-size: 82px;
			right: -165px;
			top: -13px;

			@include media-breakpoint-down(md) {
				font-size: 32px;
				right: -40px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 32px;
				right: 0;
			}
		}
	}
}

.box-recent-winners {
	margin-top: 120px;

	@include media-breakpoint-down(md) {
		margin-top: 64px;
	}
}

.box-best-games {
	margin: 40px 0 24px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.section-title {
		width: 100%;
		.icon-star {
			position: absolute;
			font-size: 41px;
			right: 100px;
			top: 40px;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		.icon-star-blur {
			position: absolute;
			font-size: 82px;
			right: 130px;
			top: -13px;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}
}

.box-promotions {
	display: flex;
	flex-direction: column;
	margin: 72px auto 0;
	width: 100%;
	max-width: 1302px;

	@include media-breakpoint-down(md) {
		margin-top: 32px;
	}
}

.box-popular-games {
	margin: 96px 0 48px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include media-breakpoint-down(md) {
		margin-top: 40px;
	}
}

.delimiter {
	display: block;
	width: 100%;
	height: 32px;
}
</style>
